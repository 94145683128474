@media only screen and (max-width: 2560px) {
    .swiper-slide img {
        width: 75%;
    }

    .swiper-button-prev {
        width: 3%;
    }

    .swiper-button-next {
        width: 3%;
    }

    .swiper-button-prev:after {
      font-size: 30px;
    }

    .swiper-button-next:after {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1450px) {

}

@media only screen and (max-width: 1250px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 980px) {
    .swiper-button-prev {
        width: 10%;
    }

    .swiper-button-next {
        width: 10%;
    }

    .swiper-button-prev:after {
        font-size: 25px;
    }

    .swiper-button-next:after {
        font-size: 25px;
    }
}

@media only screen and (max-width: 940px) {

}

@media only screen and (max-width: 780px) {

}

@media only screen and (max-width: 650px) {
    .swiper-slide img {
        width: 100%;
    }
}

@media only screen and (max-width: 550px) {

}

@media only screen and (max-width: 430px) {

}

@media only screen and (max-width: 420px) {

}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 380px) {

}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-wrapper {
    padding: 20px 0 40px 0;
}


.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.swiper-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
}

.Swiper_main {
    padding: 30px 0;
}

.Swiper_h2 {
    width: 100%;
    text-align: center;
}



.swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000014;
    height: 75%;
    margin: auto;
    top: 0;
    left: 0;
    color: #00000070;
    border-radius: 0 140% 140% 0;
    padding: 2px;
}

.swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000014;
    height: 75%;
    margin: auto;
    top: 0;
    right: 0;
    color: #00000070;
    border-radius:140% 0 0 140% ;
    padding: 2px;
}