@media only screen and (max-width: 2560px) {
    #Navbar_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .icon_navbar {
        display: none;
    }
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1450px) {

}

@media only screen and (max-width: 1250px) {

}

@media only screen and (max-width: 1190px) {
    .phone_activ {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    #Navbar_content {
        display: flex;
        justify-content: flex-start;
        align-items: start;
    }

    .transition-all {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 940px) {
    .icon_navbar {
        display: flex;
    }

    #Navbar_content {
        height: calc(100vh - 112px);
        width: 100%;
        overflow: scroll;
    }


    .transition-all {
        font-size: 18px !important;
    }

}

@media only screen and (max-width: 780px) {

}

@media only screen and (max-width: 650px) {

}

@media only screen and (max-width: 550px) {

}

@media only screen and (max-width: 430px) {

}

@media only screen and (max-width: 420px) {

}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 380px) {

}

#Navbar {
    z-index: 100;
}

#Navbar_content::-webkit-scrollbar {
    width: 0px;
    color: #676767;
}




/*.ListItem_active {*/
/*    background-color: rgba(68, 68, 68, 0.26) !important;*/
/*    transition: .9s;*/
/*}*/