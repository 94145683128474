.Cinema_flex_container{
    display: flex;
  margin-bottom: 40px;
}
.Cinema_flex_element{
    text-align: center;
    width: 312px;
    padding: 20px ;
}
.cinem_block7_img{
    display: flex;
    justify-content: flex-end;
}
#macbook{
    width: 90%;
}
#popcorn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;


}
#cinema_text2{
    font-size: 1.5em;
    padding: 100px 0;
}
/* block 4 */
.cinema_flex_box{
    display: flex;
    gap: 10px;
    margin-bottom: 40px;
}
.cinema_flex_box p{
    color: #9DA1AD;
    font-size: 0.9em;
    width: 480px;
}
.cinema_text{
    flex: 45% 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
 
}
.cinema_img{
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cinema_img img{
    width: 100%;
}
#cinema_block5_title{
    width:100%;
}

.text_wrapper {
    padding: 10% 10% 5%;
}

.equipment_element_item {
    background-color: #f7f8fb;
    border-radius: 30px;
    display: flex;
    flex: 33.33% 1;
    flex-direction: column;
    justify-content: space-between;
    max-width: 500px;
    overflow: hidden;
    margin: 10px 0;
}

.equipment_img_item {
    width: 100%;
}

.equipment_text_item {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 5%;
    text-align: start;
}


@media (max-width:1440px) {
    #cinema_text2{
        padding: 10px 0;
    }
    #popcorn{
        width: 30%;
    }
}
@media (max-width:1024px) {
    .Cinema_flex_element{
        width: 231px;
    }
    .cinema_flex_box p{
        width: 355px;
    }
}
@media (max-width:768px) {
    #cinema_text2{
        padding: 0;
    }
}

@media (max-width:725px) {
    .Cinema_flex_container{
        flex-direction: column;
        gap:10px
    }
   
    .Cinema_flex_element{
        background-color: #F7F8FB;
        border: 1px solid #E2E3E6;
        border-radius: 30px;
        padding: 10px 20px;
        display: flex;
        width: 100%;
        font-size: 0.8em;
        white-space: wrap;
        align-items: center;
   
    }
    #popcorn{
        width: 80%;
        right: 10%;
    }
    .cinema_img{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
   
    .cinema_flex_box{
      flex-direction: column;
    }
    .empty_div{
        display: none;
    }
    #cinema_block5_title{
        width: 240px;
    }
   #equipment_img_terminal{
    width: 0;
    margin-left: -50px;
   }
}
@media (max-width:375px) {
   
    .cinema_flex_box p{
        width: 270px;
    }
}