.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #90939b77;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.popup_inner{
    background-color: #FFFFFF;
    overflow: hidden;
    position: relative;
    z-index: 1;
    max-width: 600px;
    border-radius: 30px;
    animation: popup 0.8s ease-in;
}
.popup_inner2{
    animation: popup 0.8s ease-in;
    padding-right: 20px;
}
.form_inner{
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
}
.form_inner2{
    position: relative;
    flex-direction: row !important;
}

.form_inner2 h3,
.form_inner2 p{
    text-align: left;
    padding-right: 20px;
}
.form_inner p{
    font-size: 0.8em;
    color: #9DA1AD;
}
#bcg_Img{
    position: absolute;
    width: 150%;
    height: 100%;
    z-index: -1;
}
.form{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#name,
#Email,
#Phone_number{
   background-color:  #F7F8FB;
   border-radius: 10px;
   border-color:  #9DA1AD;
   padding: 15px 40px 15px 15px;
   color: #9DA1AD;
   appearance: none;
   outline: none;

}
#Phone_number{
    background-image: url("../../img/phone.svg"), url("../../img/plus-sign.png");
    background-position: 95%, 2%;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, 10px;
  
}
#Email{
    background: url("../../img/at-rec.svg") no-repeat;
    background-position: 95% center;
}
#name{
    background: url("../../img/user.svg") no-repeat;
    background-position: 95% center;
}
#Phone_number::-webkit-outer-spin-button,
#Phone_number::-webkit-inner-spin-button {
  -webkit-appearance: none;

}
.form #name:focus{
    outline: none;
    background: url("../../img/mailBlue.svg") no-repeat;
    background-position: 95% center;
    border-color:  #2B59FA;
    color: #2B59FA;
}
.form #Phone_number:focus{
    outline: none;
    background-image: url("../../img/phoneBlue.svg"), url("../../img/plus-sign.png");
    background-position: 95%, 2%;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, 10px;
    border-color:  #2B59FA;
    color: #2B59FA;

}
.form #Email:focus{
    outline: none;
    background: url("../../img/at-recblue.svg") no-repeat;
    background-position: 95% center;
    border-color:  #2B59FA;
    color: #2B59FA;
}
#call_me_btn{
    background-color: rgba(43, 89, 250, 1);
    padding: 15px 25px ;
    border-radius: 90px;
   display: flex;
   align-items: center ;
   justify-content: center;
   color:white;
   font-size: 1em;
   border: 1px solid  rgba(43, 89, 250, 1);
   cursor: pointer;
}
#call_me_btn:hover{
    background-color: #fff;
    color:rgba(43, 89, 250, 1);
    border: 1px solid rgba(43, 89, 250, 1);
    transition: 0.3s;
    cursor: pointer;
    
}
#call_me_btn:active{
    background-color: rgba(223, 227, 240, 1);
    color:rgba(43, 89, 250, 1);
    border: 1px solid rgba(223, 227, 240, 1);
    transition: 0.3s;
    
}
.check_icon{
    width: 70px;
}

.check_icon img{
    width: 100%;
}

#close_btn{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

#loading{
    width: 70px;

}
#Optional_field{
    text-align: left;
    margin-top: -15px;
    font-size: 11px;
    font-style: italic;
}
@media (max-width:1024px) {
    .check_icon{
        width: 50px;
    }
}
@keyframes popup {
    0%{
        margin-top: 1500px;
    }
    100%{
        margin-top: 0;
    }
}

.input_class {
    border: 1px solid #0000003d;
    outline: antiquewhite;
    background-color: white;
}
