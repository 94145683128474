@media only screen and (max-width: 2560px) {
    #printers h2{
        font-size: 28px;
    }

    .CardFooter_button {
        font-size: 13px!important;
    }
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1450px) {

}

@media only screen and (max-width: 1250px) {

}

@media only screen and (max-width: 1190px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 940px) {

}

@media only screen and (max-width: 780px) {

}

@media only screen and (max-width: 650px) {
    .printer_element .printer_element_content .printer_element_img {
        padding: 10px;
    }

}

@media only screen and (max-width: 550px) {

}

@media only screen and (max-width: 430px) {
    .printer_element .printer_element_content .printer_element_img {
        padding: 10px;
    }

    #printers h2{
        width: 100%;
        text-align: center;
        font-size: 20px;
    }

    .CardHeader_img {
        width: 40% !important;
    }

    .DialogHeader_h {
        font-size: 13px;
    }

    .DialogFooter_button {
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 420px) {

}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 380px) {
    .CardHeader_img {
        width: 30% !important;
    }

    .DialogHeader_h {
        font-size: 12px;
    }
}


#printers {
    padding: 0 0 60px 0;
}


.printers_inner{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content:space-evenly ;
}

.printer_element {
    background-color: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.printer_element {
    box-shadow: rgb(216 220 233) 0px 0px 10px 0px;
}

.printer_element:hover{
    transition: 0.1s;
    transform: scale(1.01)
}

.printer_img{
    width: 250px;
    height: 250px;
    margin: 0 auto 10px;
}

.printer_img img{
    width: 100%;
}

.DialogDefault_name {
    border-bottom: 1px solid #8d8d8d;
}

.Active_button {
    background-color: green;
}

.Badge {
    position: relative;
}

.Badge:hover .Badge_main {
    display: flex !important;
    transition: .9s;
}

.Badge_main {
    display: none;
    transition: .9s;
    z-index: 10;
    box-shadow: rgb(216 220 233) 0px 0px 10px 0px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 10px;
}

.Badge_main_scroll::-webkit-scrollbar {
    width: 1px;
}

.Badge_main_scroll::-webkit-scrollbar-thumb {
    background: #888;
}

.Card_info_table {
    border-radius: 0;
}

.Card_info_table::-webkit-scrollbar {
    width: 1px;
}

.Card_info_table::-webkit-scrollbar-thumb {
    background: #888;
}