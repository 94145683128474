
.fastfood_wrapper{
    width: 100%;
  
}
/* header */
.fastfood_header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}
.fastfood_header_text{
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    width: 60%;
    justify-content: center;
}
.fastfood_header_text p{
    width: 66%;
   margin: 5% auto;
}
.fastfood_header_text span{
color: #2B59FA;
}
.fastfood_header_picture {
    position: relative;
    width: 100%;
}
.fastfood_header_picture img{
    width: 100%;
    border-radius: 30px;
}
#pic_for_mobile{
    display: none;
}
#button_component{
    position: absolute;
    bottom: -5%;
    width: 100%;
  
}
#button_component button{
    margin: 0 auto;
}
.header_picture img{
    width: 100%;
 
}

/*Popular_equipment_categories  */
.categories_slide_wrapper{
    margin-bottom: 5%;
}

.equipment_element{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: rgba(247, 248, 251, 1);
    border-radius: 30px;
    overflow: hidden;
    flex: 33.33% 1;
    max-width: 500px;

}
.second_title{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 auto;
}
.title_h2{
    margin:5px auto;
    max-width: 500px;
}
.subtitle_h2{
    color: rgba(157, 161, 173, 1);
    font-size: 0.8em;
    margin-bottom: 4%;
}
.equipment_text{
    width: 100%;
    font-size: 1.2em;
    text-align: center;
    font-weight: 500;
    padding: 10px;
}


.equipment_element span{
    color: #2B59FA;
   
}

.equipment_text img{
    padding: 0 2%;
}

.equipment_subtext{
    color: rgba(157, 161, 173, 1);
    font-size: 0.8em;
}

.equipment_img{
    width: 50%;
}

.element_img{
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* block3 */
.block_list{
    width: 100%;
    background-color: rgba(247, 248, 251, 1);
    border-radius: 30px;
    padding: 5% 0 0 5%;
    position: relative;
    display: flex;
    overflow: hidden;
    z-index: -10;
    margin-bottom: 5%;
}

.support_text{
   
    padding-right: 20px;
    display: flex;
    flex-direction: column;

}
.support_text h2 ,
.support_text p{
    margin-bottom: 5%;
}
.support_text p{
    color:#9DA1AD;
    display: flex;
    gap: 5%;
}
.support_text img{
    width: 10px;
}

.comp{

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.comp img{
    width: 100%;
    margin-bottom: 0;
    z-index: 10;
}
#BCGimg{
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;

}

.hidden_comp{
    display: none;
}

/* block */
.title_5{
    padding: 5% 30%;
    text-align: center;
}
/* .subtitle_5{
 
} */
.title_5_text_wrapper{
    padding: 5%;
}
.title_5_text{
    font-size: 1.1em;
    margin-bottom: 5%;
    width: 240px;
}
.title_5_element_img{
    display: flex;
    justify-content: flex-end;
}
.title_5_img{
    width: 90%;
}

/* Blue_block */
.Blue_block{
    margin:  5% 0;
    border-radius: 30px;
    width: 100%;
   padding: 5%;
   display: flex;
   /* flex-direction: column;
   align-items: center;
   justify-content: center; */
   justify-content: space-between;
   position: relative;
   background: url('../../img//Slice\ 1bcgBlue.svg') 0 50%/100% auto no-repeat;
}
.Blue_block p{
    color: rgba(255, 255, 255, 1);
    font-size: 30px;
    flex: 50%;
}
.Blue_block img{
    position: absolute;
    z-index: -10;
    width: 100%;
}
.button_wrap{
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Blue_block_button{
    /* height: 90px;
    width: 280px; */
    border: none;
    background-color: rgba(255, 255, 255, 1);
    padding: 5% 10%;
    border-radius: 90px;
   display: flex;
   align-items: center ;
   justify-content: space-between;
   color:rgba(20, 25, 43, 1);
   border: 1px solid  rgba(255, 255, 255, 1);
   font-size: 1.2em;
   font-weight: 600;
}

/* Advantages */
.flex_wrapp{
    display: flex;
    flex-wrap: wrap;
    background-color: #F7F8FB;
    padding: 5%;
    border-radius: 35px;
}
.element{
    display: flex;
    flex-direction: column;
     padding-bottom: 30px;
    flex: 0 1 33.33%;
}
.element_icon{
    background-color: #2B59FA;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
}
.element p{
    color: rgba(157, 161, 173, 1);
    font-size: 0.7em;
    width: 310px;
}
.element h4{
 
    margin-bottom: 3%;
}



@media (max-width:2560px) {
    .categories_flex{
        justify-content: space-around;
        gap: 5%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width:1440px) {
  
}

@media (max-width:1100px) {
    .categories_flex{
        gap: 5%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .equipment_element_inner {
        margin: 10px;
    }
}


@media (max-width:1024px) {
    .fastfood_header_text{
        padding:0 30px
    }

    .support_text{
        padding-right: 10%;
    }
    .header_text{
        width: 50%;
    }
    .Blue_block p {
        font-size: 20px;
    }
    .title_5_text{
        width: 170px;
    }
    .element p{
        width: 210px;
    }
}
@media (max-width:768px){
    .fastfood_header_text{
        width: 70%;
    }
   
    .second_title{
        padding:0 30%;
    }
    /* .flex_wrapp{

    } */
    .element p{
        width: 210px;
    }
    .element_icon{
        width: 40px;
        height: 40px;
    }
  
}

@media (max-width:725px) {
    .fastfood_header{
        margin-bottom: 15%;
        align-items: flex-start;
    }
    .fastfood_header_text{
        text-align: left;
        width: 100%;
        padding: 0;
    }
    
    .fastfood_header_text p{
        width: 90%;
    }
    #pic_for_mobile{
        display: block;
    }
    #pic_for_laptop{
        display: none;
    }
    /* #button_component{

    } */
    .fastfood_header_text h1{
        margin-left: 0%;
    }
    .fastfood_header_text p{
        margin-left: 0%;
    }
    .categories_flex{
        flex-direction: column;
        gap:10px
    }
    #column{
        flex-direction: row;
    }
    /*.block{*/
    /*    flex-direction: column;*/
    /*}*/
    .comp{
        display: none;
    }
    .hidden_comp{
        display: flex;
    }
    .second_title{
        padding:0 5% 0 0;
        text-align: left;
    }
    .title_5{
        padding: 5% 0;
        text-align: left;
    }
    .Blue_block{
        flex-direction: column;
        text-align: center;
        padding: 5% 14%;
    }
    .Blue_block p{
        padding-bottom: 4%;
    }
    .float_wrapper h2{
        text-align: left;
    }
    .element p{
        width: 300px;
    }
    .element {
        padding-bottom: 20px;
    }
    .flex_wrapp{
        padding: 25px;
    }

}

@media (max-width:320px) {
    .element p{
        width: 210px;
    }
}