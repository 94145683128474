@media only screen and (max-width: 2560px) {
    .blog_Item_list .blog_Item_content {
        bottom: -10px;
        width: 70%;
        border-radius: 20px;
        position: absolute;
        box-shadow: 0px 0px 20px 1px #0000004f;
    }

    .blog_Item_list {
        width: 100%;
        height: 20vh;
    }
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1450px) {

}

@media only screen and (max-width: 1250px) {

}

@media only screen and (max-width: 1190px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 940px) {
    .blog_Item_list .blog_Item_content {
        bottom: 0px;
        width: 90%;
        border-radius: 20px;
        position: absolute;
        box-shadow: 0px 0px 20px 1px #0000004f;
    }
}

@media only screen and (max-width: 780px) {
    .blog_Item_list {
        width: 100%;
        height: 40vh;
    }

    .blog_Item_list .blog_Item_content {
        width: 100%;
        width: 100%;
        border-radius: 0;
        box-shadow: 0 0 0 0;
    }
}

@media only screen and (max-width: 650px) {

}

@media only screen and (max-width: 550px) {

}

@media only screen and (max-width: 430px) {

}

@media only screen and (max-width: 420px) {

}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 380px) {

}

.blog_Item_list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.blog_Item #blog_Item_img {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
}

.blog_Item_list .blog_Item_content {
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.blog_Item_list .blog_Item_content .blog_Item_content_title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    color: #171717;
    margin: 8px 0;
}

.blog_Item_list .blog_Item_content .blog_Item_content_text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: #404040;
    font-weight: 400;
    margin: 8px 0;
}

.blog_Item_list .blog_Item_content .blog_Item_content_data {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #404040;
    margin: 8px 0;
}