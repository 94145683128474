.Hotels_flex_block1{
    padding: 0 50px;
}
/* .Hotels_flex_block2{} */
.Hotels_flex_block3{
    padding: 0 400px;
}
.Hotels_miniText{
    max-width: 290px;
}
.Hotels_maxText{
    max-width: 425px;
}
.Hotels_system_item{
    flex: 33%;
}
.Hotels_desctopImg{
    flex: 50%;
}
.Hotels_text_row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:15px
}
.Hotels_text_column{
    display: flex;
}
.Hotels_text_row h4{
    margin-bottom: 10px;
    flex: 50%;
}
.Hotels_text_row p{
    margin-bottom: 10px;
    flex: 50%;
    padding-right: 10px;
}
.Hotels_desctopImg{
    display: flex;
    justify-content: right;
    align-items: flex-end;
}
@media  (max-width:1440px) {
    .Hotels_flex_block3{
        padding: 0 320px;
    } 
}
@media (max-width:1024px) {
    /* .Hotels_flex_block3{
       
    } */
}
@media (max-width:725px) {
    .Hotels_flex_block1{
        padding: 0;
    }
    .Hotels_flex_block3{
        padding: 0;
    }
    .Hotels_desctopImg #iphone{
        width: 100%;
    }
}