
.Mobile_block2_img{
    position: absolute;
    bottom: -5%;
    right: 20px;
    width: 25%;
}
.Mobile_img2{
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 40%;
}
.Mobile_img3{
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 40%;
}
.Mobile_text{
    padding: 10% 5% 5% 0;
}
@media (max-width:768px) {
    .Mobile_block2_img{
        width: 45%;
    }
}

@media (max-width:725px) {
    .Mobile_block2_img{
        width: 80%;
        position: absolute;
        bottom: -50px;
        right: 25px;
    }
    .Mobile_text{
        padding: 20px 0 300px 0;
    }
    .Mobile_img2{
        width: 90%;
    }
    .Mobile_img3{
        width: 90%;
    }
    
}
@media (max-width:375px) {
    .Mobile_block2_img{
        width: 90%;
       
    }
}