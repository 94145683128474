@media only screen and (max-width: 2560px) {
    .Blog .Blog_Swiper {
        max-width: 80%;
    }

    .Blog .swiper-button-next, .Blog .swiper-button-prev {
        top: 50%;
    }

    .Blog .Blog_card {
        justify-content: flex-start;
    }

    .Blog .swiper-slide img {
        object-fit: cover;
    }
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1450px) {

}

@media only screen and (max-width: 1250px) {

}

@media only screen and (max-width: 1190px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 940px) {
    .Blog .Blog_card {
        justify-content: center;
    }
}

@media only screen and (max-width: 780px) {

}

@media only screen and (max-width: 650px) {
    .Blog .Blog_Swiper {
        max-width: 100%;
    }

    .Blog .swiper-button-next, .Blog .swiper-button-prev {
        top: 35%;
    }

    .Blog .swiper-slide img {
        object-fit: cover;
        object-position: left;
    }
}

@media only screen and (max-width: 550px) {

}

@media only screen and (max-width: 430px) {

}

@media only screen and (max-width: 420px) {

}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 380px) {

}



.Blog {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Blog .Blog_card {
    width: 100%;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 80px;
    padding: 30px 0;
    border-top: 1px solid #cdcdcd91;
}

.Blog .Blog_card .Card_info_card {
    box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 5%);
}

.Blog .swiper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

.Blog .swiper-wrapper {
    padding: 20px;
}


.Blog .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: rgba(123, 231, 128, 0);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.Blog .swiper-slide img {
    width: 100%;
    display: block;
    max-height: 500px;
    margin: 0 auto;
    border-radius: 6px;
}

.Blog .swiper-button-next, .Blog .swiper-button-prev {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #333; /* Цвет иконки */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Тень */
    cursor: pointer;
    transition: all 0.3s ease; /* Плавный переход */
    transform: translateY(-50%);
    z-index: 10;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
}

.Blog .swiper-button-next {
    right: 15px; /* Смещение кнопки вперёд */
}

.Blog .swiper-button-prev {
    left: 15px; /* Смещение кнопки назад */
}



.Blog .swiper-button-next:after,
.Blog .swiper-button-prev:after {
    height: 24px;
    font-size: 22px;
    color: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Blog .swiper-button-next:after {
    content: "→"; /* Стрелка вправо */
}

.Blog .swiper-button-prev:after {
    content: "←"; /* Стрелка влево */
}

/* Эффект наведения */
.Blog .swiper-button-next:hover, .Blog .swiper-button-prev:hover {
    background-color: #33333380; /* Изменение цвета фона при наведении */
    color: #ffffff ; /* Изменение цвета иконки при наведении */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Усиление тени */
}

.Blog .swiper-button-next:hover:after,
.Blog .swiper-button-prev:hover:after {
    color: #ffffff; /* Цвет иконки при наведении */
}