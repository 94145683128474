@media only screen and (max-width: 2560px) {
    .AboutUs .AboutUs_header p {
        width: 35%;
    }

    .AboutUs .AboutUs_header {
        height: 60vh;
    }

    .AboutUs .AboutUs_header .AboutUs_header_text {
        height: 60vh;
    }
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1450px) {

}

@media only screen and (max-width: 1250px) {
    .AboutUs .AboutUs_header p {
        width: 55%;
    }
}

@media only screen and (max-width: 1024px) {
    .AboutUs .AboutUs_header p {
        width: 55%;
    }
}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 940px) {

}

@media only screen and (max-width: 780px) {
    .AboutUs .AboutUs_header p {
        width: 100%;
    }


    .AboutUs .AboutUs_header {
        height: 48vh;
    }

    .AboutUs .AboutUs_header .AboutUs_header_text {
        height: 48vh;
    }
}

@media only screen and (max-width: 650px) {

}

@media only screen and (max-width: 550px) {
    .AboutUs .AboutUs_header {
        height: 50vh;
    }

    .AboutUs .AboutUs_header .AboutUs_header_text {
        height: 50vh;
    }
}

@media only screen and (max-width: 430px) {

}

@media only screen and (max-width: 420px) {

}

@media only screen and (max-width: 390px) {
    .AboutUs .AboutUs_header {
        height: 60vh;
    }

    .AboutUs .AboutUs_header .AboutUs_header_text {
        height: 60vh;
    }
}

@media only screen and (max-width: 380px) {

}

.AboutUs {
    width: 100%;
}

.AboutUs .AboutUs_header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    background-color: #00000024;
    border-radius: 30px;
    background-image: url("../../img/about_1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.AboutUs .AboutUs_header .AboutUs_header_text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px;
}

.AboutUs  .AboutUs_header h1 {
    padding: 10px;
    width: 100%;
    color: #002c6a;
}

.AboutUs .AboutUs_header p {
    color: #ffffff;
    font-weight: 900;
    padding: 10px;
    font-size: 18px;
}

.AboutUs .AboutUs_content {
    width: 100%;
    padding: 30px 0;
}

.AboutUs .AboutUs_content p {
    padding: 20px 0;
}
