.button{
    border: none;
    background-color: rgba(43, 89, 250, 1);
    padding: 15px 25px 15px 45px;
    border-radius: 90px;
   display: flex;
   align-items: center ;
   justify-content: space-between;
   color:white;
   font-size: 1em;
   border: 1px solid  rgba(43, 89, 250, 1);
 
}
.button img{
    width: 50px;
}

.button:hover{
    background-color: #fff;
    color:rgba(43, 89, 250, 1);
    border: 1px solid rgba(43, 89, 250, 1);
    transition: 0.3s;
    cursor: pointer;
    
}


.button:hover .arrow_bcg{
    background-color:rgba(43, 89, 250, 1);
    transition: 0.3s;

}
.button:hover img{ 
    color: #fff;
    transition: 0.3s;
}
.button:active{
    background-color: rgba(223, 227, 240, 1);
    color:rgba(43, 89, 250, 1);
    border: 1px solid rgba(223, 227, 240, 1);
    transition: 0.3s;
    
}
.arrow_bcg{
    background-color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

#img1{
    width: 20px;

}
#img2{
    display: none;
    width: 20px;
}
.button:hover #img2{
    display: flex;
    transition: 0.3s;
}
.button:hover #img1{
    display: none;
    transition: 0.3s;
}
@media (max-width:1024px) {
    .button{
        padding: 6px 10px 6px 20px; 
    }
}