#Retail_phone2{
    width: 100%;
}
#Retail_phone1{
    padding-top: 50px;
    flex: 30%;
}
#Retail_support_block{}
#Retail_support_text p{
    display: flex;
    gap: 20px;
}
.Retail_flex_block1{
    
}
@media (max-width:725px) {
    #Retail_support_block{
        padding: 0;
    }
    #Retail_support_text{
        padding: 20px;
    }
    
}