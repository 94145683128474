.EntertCenter_header_subtitle{
    padding: 25px 20px;
}
.Entert_center_flex_container{
    display: flex;
    justify-content: center;
    padding:  0 20px 20px;
    gap: 10px;
}
.Entert_center_flex_container2{
    display: none;
}
.entert_grid_element{
    background-color: #F7F8FB;
    border: 1px solid #E2E3E6;
    border-radius: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

#element_img{
    margin-top: -25px;
}

#desktop{
    width: 80%;
}
.EntertCenter_element_img{
    
    justify-content: right;
}
.EntertCenter_element_img img{
    width: 90%;
}
.EntertCenter_flex_row{
    flex: 50%;
}
@media (max-width:725px) {
    .EntertCenter_header_subtitle{
        padding: 15px 35px 15px 0;
    }
    .Entert_center_flex_container{
       display: none;
    } 
    .Entert_center_flex_container2{
        display: flex;
        gap: 8px;
        padding-bottom: 10px;
        font-size: 0.7em;
    }
    .entert_grid_element{
        padding: 5px 10px;
    }
    #categories_flex{
        padding: 0;
    }
}