.MenuHandler {
    padding: 0px;
    cursor: pointer !important;
}

.MenuHandler_item {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
}

.MenuHandler_item span {
    padding-left: 15px;
}



