/* header */
.PublicFood_header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}
.PublicFood_header_text{
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    width: 60%;
    gap: 30px;
    margin: 0 auto;
    padding-bottom: 20px;
    /* justify-content: center; */
}

.PublicFood_header_text span{
    color: #2B59FA;
}
.PublicFood_header_picture {
    position: relative;
    width: 100%;
}
#PublicFood_pic_for_mobile{
    display: none;
}
#PublicFood_button_component{
    position: absolute;
    bottom: -7%;
    width: 100%;
  
}
#PublicFood_button_component button{
    margin: 0 auto;
}
.PublicFood_header_picture img{
    width: 100%;
}
/* block 2 */
.PublicFood_block2{
    position:relative;
    display: flex;
    margin: 5% 0;
    
    
}

#PublicFood_block{
    padding: 0 0 0 5%;
    margin-top: 5%;
}

#PublicFood_block2_img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35%;
}
.PublicFood_handAndPhone{
    flex: 50%;
}
.PublicFood_block2_text{
    padding: 50px 0; 
}
/* pic and text flex box */
.PublicFood_flex_container{
    margin-bottom: 30px;
}
.PublicFood_flex_container h2{
    margin: 0 auto;
    width: 40%;
    text-align: center;
    margin-bottom: 50px;
}
.PublicFood_flex_text{
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    /* letter-spacing: 0.5px; */
    gap: 20px;
}
.PublicFood_flex_text p{
    color: #9DA1AD;
    display: flex;
    align-items: center;
    gap: 20px;
    padding-right: 50px;
}
/* .PublicFood_flex_text img{
    
} */
.PublicFood_flex_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}
.PublicFood_flex_box__element{
    display: flex;
    justify-content: space-between;
    gap: 70px;
}
#PublicFood_box__element{
    flex-direction: row-reverse;
}
.PublicFood_flex_img{
    flex: 50%;
}
.PublicFood_flex_img img{
    width: 100%;
}

/* block3 */
.PublicFood_block{
    width: 100%;
    background-color: rgba(247, 248, 251, 1);
    border-radius: 30px;
    padding: 5% 0 0 5%;
    position: relative;
    display: flex;
    overflow: hidden;
    z-index: -10;
    
}
.PublicFood_support_text{
    flex: 50%;
    /* padding-right: 20%; */
    display: flex;
    flex-direction: column;

}
.PublicFood_support_text h2{
  
}

.PublicFood_support_text h2 ,
.PublicFood_support_text p{
    margin-bottom: 5%;
}
.PublicFood_support_text p{
    color:#9DA1AD;
    display: flex;
    gap: 5%;
}

.PublicFood_comp{

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.PublicFood_comp img{
    width: 100%;
    margin-bottom: 0;
    z-index: 10;
}
#PublicFood_BCGimg{
    position: absolute;
    width: 100%;
    z-index: -1;
    left: 0;
    top: 0;
}

.PublicFood_hidden_comp{
    display: none;
}
#comp2{
    display: none;
}
/* Advantages */
.PublicFood_flex_wrapp{
    background-color: #F7F8FB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 45px;
    border-radius: 30px;
    margin: 20px 0;
    gap: 35px;
}
.PublicFood_flex_row{
    display: flex;
    justify-content: space-between;
    gap:50px;
    
}
.PublicFood_element{
    margin-bottom: 20px;
    flex: 33.33%;
    
}
.PublicFood_element h3{
    margin-bottom: 10px;
}
.PublicFood_element p{
    color: #9DA1AD;
    font-size:0.9em ;
    line-height: 1.3em;
    /* letter-spacing: 0.5px; */
}
.PublicFood_element_icon{
    background-color: #2B59FA;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
/* System */
.PublicFood_system_block{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.PublicFood_system_paragraf{
    color: #9DA1AD;
    font-size: 0.9em;
}
.PublicFood_system_paragraf,
.PublicFood_system_title{
    padding:20px 0;
    width: 40%;
    text-align: center;
}
.PublicFood_flex_block{
    display: flex;
    gap: 10px;
    margin-bottom: 25px;
    width: 100%;
    justify-content: space-between;
}
.PublicFood_flex_block1{
    padding: 0 60px;
}
/* .PublicFood_flex_block2{} */
.PublicFood_flex_block3{
    padding: 0 70px;
}
.PublicFood_flex_block4{
    padding: 0 450px;
}
.PublicFood_system_item{
    display: flex;
    background-color: #F7F8FB;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 25px;
}
.PublicFood_systemIcon{
    padding: 10px;
    border-radius: 10px;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PublicFood_miniText{
    max-width: 240px;
}
.PublicFood_maxText{
    max-width: 430px;
}
.mini_item{
    flex:20%
}
.max_item{
    flex:40%
}

/* block 5 */
.PublicFood_text_column{
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* justify-content: space-evenly; */
    flex: 50%;
}
.PublicFood_text{
    display: flex;
}
.PublicFood_iphone img{
    width: 80%;
}
/* Blue_block */
.PublicFood_Blue_block{
    margin:  5% 0;
    border-radius: 30px;
    width: 100%;
   padding: 5%;
   display: flex;
   justify-content: space-between;
   position: relative;
   background: url('../../img/Slice\ 1bcgBlue.svg') 0 50%/100% auto no-repeat;
}
.PublicFood_Blue_block p{
    color: rgba(255, 255, 255, 1);
    font-size: 30px;
    flex: 50%;
}
.PublicFood_Blue_block img{
    position: absolute;
    z-index: -10;
    width: 100%;
}
.PublicFood_button_wrap{
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PublicFood_Blue_block_button{
    /* height: 90px;
    width: 280px; */
    border: none;
    background-color: rgba(255, 255, 255, 1);
    padding: 5% 10%;
    border-radius: 90px;
   display: flex;
   align-items: center ;
   justify-content: space-between;
   color:rgba(20, 25, 43, 1);
   border: 1px solid  rgba(255, 255, 255, 1);
   font-size: 1.2em;
   font-weight: 600;
}


/* sliding-line  */
.PublicFood_float_wrapper{
    width: 100%;
}
.PublicFood_float_container{
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
}   
.PublicFood_sliding_line{
    position: absolute;
    display: flex;
    transition:  3s ;
    top: 0;
    left: 0%;
    /* animation: slide 0s infinite; */

}
.PublicFood_sliding_line img{
    width: 150px;
    padding: 10px;
}
.PublicFood_sliding_line img:hover{
    opacity: 3;
}
.PublicFood_float_wrapper h2{
    text-align: center;
    padding: 5% 0 0;
}
/* @keyframes slide {
    0%{
        left: 0%;
    }
    50%{
        left:calc(-100% - 100px);
    }
    100%{
       left: 0%;
    }
} */


@media (max-width:1440px) {
    .PublicFood_flex_container h2{
        width: 50%;
    }
    .PublicFood_system_block{
        font-size: 0.8em;
    }
    .PublicFood_flex_block4{
        padding:0 280px ;
    }
   
}
@media (max-width:1024px) {
    .PublicFood_equipment_text{
        width: 140px;
    }
    .PublicFood_support_text{
        padding-right: 10%;
    }
    .PublicFood_header_text{
        width: 50%;
    }
    .PublicFood_Blue_block p {
        font-size: 20px;
    }
    .PublicFood_title_5_text{
        width: 170px;
    }
    .PublicFood_flex_wrapp{
        padding: 50px 40px;
    }
    .PublicFood_element p{
        width: 210px;
    }
    .PublicFood_flex_container h2{
        width: 40%;
    }
    .PublicFood_support_text{
        padding-right: 10px;
        
    }
    .PublicFood_flex_row{
        gap: 20px;
    }
}
@media (max-width:768px){
    .PublicFood_header_text{
        width: 70%;
        gap: 10px;
    }
    .PublicFood_second_title{
        padding:0 30%;
    }
    /* .PublicFood_flex_wrapp{

    } */
    .PublicFood_element p{
        width: 100%;
    }
    .PublicFood_element_icon{
        width: 40px;
        height: 40px;
    }
    .PublicFood_flex_container h2{
        width: 50%;
    }
    
    .PublicFood_flex_text p{
        padding-right: 0;
    }
    .support_text_block3{
     flex: 80%;
        
    }
    .PublicFood_system_item{
        padding: 10px 20px;
        font-size: 0.8em;
    }
    .PublicFood_flex_block1{
        padding: 0 20px;
    }
    .PublicFood_flex_block3{
        padding: 0 25px;
    }
    .PublicFood_flex_block4{
        padding: 0 190px;
    }
    /* .PublicFood_block{
         margin-bottom: 20px; 
    } */
  
}

@media (max-width:725px) {
    .PublicFood_header{
        margin-bottom: 15%;
        align-items: flex-start;
    }
    .PublicFood_header_text{
        text-align: left;
        width: 100%;
    }
  
    #PublicFood_pic_for_mobile{
        display: block;
    }
    #PublicFood_pic_for_laptop{
        display: none;
    }
    /* #PublicFood_button_component{

    } */
    .PublicFood_flex_container h2{
        width: 90%;
        margin-left: 0;
        text-align: left;
        margin-bottom: 15px;
    }
    .PublicFood_header_text h1{
        margin-left: 0%;
    }
    .PublicFood_header_text p{
        margin-left: 0%;
    }
    .PublicFood_categories_flex{
        flex-direction: column;
        gap:10px
    }
    #PublicFood_column{
        flex-direction: row;
    }
    .PublicFood_block{
        flex-direction: column;
    }
    #comp1{
        display: none;
    }
    #comp2{
        display: block;
    }
    .PublicFood_hidden_comp{
        display: flex;
    }
    .PublicFood_second_title{
        padding:0 5% 0 0;
        text-align: left;
    }
    .PublicFood_title_5{
        padding: 5% 0;
        text-align: left;
    }
    .PublicFood_Blue_block{
        flex-direction: column;
        text-align: center;
        /* padding: 5% 14%; */
    }
    .PublicFood_Blue_block p{
        padding-bottom: 4%;
    }
    .PublicFood_float_wrapper h2{
        text-align: left;
    }
    .PublicFood_element p{
        width: 100%;
    }
    .PublicFood_element {
        padding-bottom: 20px;
    }
    .PublicFood_flex_wrapp{
        padding: 25px;
        gap: 0;
    }
    .PublicFood_flex_box__element{
        flex-direction: column;
        gap: 20px;
    }
    #PublicFood_box__element{
        flex-direction: column;
        gap: 20px;
    }
    .PublicFood_flex_text{
        padding-right: 0px;
        padding-left: 0;
    }
    .PublicFood_block2{
        flex-direction: column;
    }
    .PublicFood_block2_text{
        padding: 20px 0 300px 0;
        
    }
    #PublicFood_block2_img{
        width: 100%;
    }
    #PublicFood_BCGimg{
        width: auto;
    }
    .support_text_block3{
        padding-right: 90px;
    }
    .PublicFood_flex_row{
        flex-direction: column;
        gap: 0;
    }
    .PublicFood_flex_block{
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        margin-bottom: 10px;
    }
    .PublicFood_system_block{
        align-items: flex-start;
    }
    .PublicFood_system_paragraf,
    .PublicFood_system_title{
        width: 90%;
        text-align: start;
    }
    .PublicFood_support_text{
        font-size: 0.8em;
    }
    
}

@media (max-width:320px) {
    .PublicFood_element p{
        width: 210px;
    }
    .PublicFood_block2_text{
        padding: 20px 0 250px 0;
        
    }
    .PublicFood_flex_container h2{
        width: 100%;
    }
    .PublicFood_support_text {
        padding-right: 35px;
    }
}