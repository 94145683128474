@media only screen and (max-width: 2560px) {
    .Item img {
        object-fit: cover;
    }
}

@media only screen and (max-width: 1920px) {

}

@media only screen and (max-width: 1450px) {

}

@media only screen and (max-width: 1250px) {

}

@media only screen and (max-width: 1190px) {

}

@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 940px) {

}

@media only screen and (max-width: 780px) {

}

@media only screen and (max-width: 650px) {
    .Item img {
        object-fit: inherit;
    }
}

@media only screen and (max-width: 550px) {

}

@media only screen and (max-width: 430px) {

}

@media only screen and (max-width: 420px) {

}

@media only screen and (max-width: 390px) {

}

@media only screen and (max-width: 380px) {

}

.Item {
    width: 100%;
    position: relative;
}

.Item img {
    border-radius: 20px;
    object-position: center;
}


