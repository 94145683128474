@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
 }

*{
    font-family:   Arial, Helvetica, sans-serif;
    color: rgba(14, 8, 43, 1);
    box-sizing: border-box;
    margin: 0 ;
    /* padding321 q 0%; */
}

img {
  max-width: none;
}

.App {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  /*max-width: 1440px;*/
  margin: 0 auto;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
}
p{
  line-height: 1.4em;
}
main{
  padding: 110px 100px 0;
  width: 100%;
  min-height: 100vh;
 
}
.BackImg{
  position: absolute;
  width: 100%;
  z-index: -10;
}
.BackImgmobile{
  display: none;
  position: absolute;
  width: 100%;
  z-index: -10;
  top: -50px;
}
.App h1{
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
}

.App h2{
  font-size: 30px;
  font-weight: 600;
}

.slick-prev {
  left: -52px !important;
}
.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  color: #000000;
  font-size: 45px;
}
.thumbnail-wrapper {
  margin-top: -350px;
  height: 85px;
  width: 90%;
  margin-left: 60px;
}
@media (max-width:1440px) {
  .App h1{
    font-size: 2em;
  }
}
@media (max-width: 1024px) {
  .App{
    font-size: 14px;
  }
  main {
    padding: 100px 20px 0;
  }
}
@media (max-width: 765px)  {
  .BackImg{
    display: none;
  }
  .BackImgmobile{
    display: block;
  }
}