.MainPage_MainPage_main_wrapper{
    width: 100%;
  
}
.MainPage_title_h2,
.MainPage_chaos_title{
    padding: 5% 0; 
    text-align: center;
    width: 40%;
    margin: 0 auto;
}
.MainPage_main_section{
    margin-bottom: 15%;
}
.MainPage_main_section_text{
    padding-top: 3%;
}
.MainPage_buttons_flex{
    display: flex;
    gap: 20px;
    font-size: 0.8em;
}
.MainPage_title_button{
    display: flex;
    border:1px solid rgba(43, 89, 250, 0.25);
    border-radius: 90px;
    width: fit-content;
    padding: 5px 10px;
    margin-bottom: 30px;
}
.MainPage_title_button img{
margin-right: 10px;
/* width: 100%; */
}

#MainPage_HeadImg{
    width: 40%;
    position: absolute;
    top: 100px;
    z-index: -10;
    max-width: 1000px;
}
#MainPage_HeadImg2{
    display: none;
    width: 100%;
    overflow: hidden;
    /* position: relative; */
}


/* 2 block */
.MainPage_flex_container h2{
    text-align: left;
    margin-bottom: 20px;
}
.MainPage_flex_items{
    display: flex;
    justify-content: space-between;
}
.MainPage_flex_item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 23%;
    background-color: rgba(247, 248, 251, 1);
    border-radius: 20px;
    margin-bottom: 10px;
}
.MainPage_flex_item:hover,
.MainPage_flex_item:active{
    background-color: #DFE3F0;
    transition: 0.5s;
    cursor: pointer;
}
.MainPage_flex_item__title{
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
  
}
.MainPage_flex_item__title img{
    width: 15%;
    padding-left: 5px;
    /* min-width: 15px; */
}
.MainPage_product_img{
    width: 100%;
    height: 200px;
    border-radius: 0 0px 15px 15px;
}
/* grid */
.MainPage_grid_wrapper1{
   display: grid;
   grid-template-columns: 30.67% 30.67% 30.67% ;
   gap: 4%;
   margin-bottom: 4%;
}
.MainPage_grid_wrapper2{
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
 }
.MainPage_grid_item{
    background-color: rgba(247, 248, 251, 1);
    border-radius: 40px;
    padding: 5%;
}
.MainPage_grid_item > p{
    display: flex;
    justify-content: space-around;
    padding: 30px 30px 30px 0;
    color: rgba(157, 161, 173, 1);
}
.MainPage_item_name{
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.MainPage_item_name h3{
    font-size: 1.2em;
    font-weight: 500;
    width: 50%;
    padding-left: 30px;
}
.MainPage_grid{
    margin: 20px 0;
}
/* flex numeric block */
/* .MainPage_chaos_title{
   
} */
.MainPage_flex_box{
    display: flex;
    margin-bottom: 50px;
    gap: 20px;
}
.MainPage_flex_elem{
    position: relative;
    overflow: hidden;
    width: 50%;
    border-radius: 20px;
    padding: 5% 2% 2% 2%;
    background-color: rgba(247, 248, 251, 1);
}
.MainPage_flex_number{
    background-color: rgba(43, 89, 250, 1);
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -3%;
    left: -1%;
    border-radius: 50px;
}
.MainPage_flex_number > p{
    color: white;
    font-size: 1.5em;
    font-weight: 700;
}
/* .MainPage_flex_text{} */

/* support part */
.MainPage_support_block{
    width: 100%;
    background-color: rgba(247, 248, 251, 1);
    border-radius: 30px;
    padding: 1% 5% 0 5%;
    position: relative;
    display: flex;
    overflow: hidden;
    z-index: -10;
}
.MainPage_support_text{
    flex: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.MainPage_support_text h2 ,
.MainPage_support_text p{
    margin-bottom: 5%;
}
.MainPage_support_text p{
    color:#9DA1AD
}

.MainPage_phone1{
    flex: 60%;
    display: flex;
    align-items: flex-end;
}
.MainPage_phone1 img{
    width: 100%;
    margin-bottom: 0;
}
.MainPage_phone2{
 display: none;
}
#MainPage_BCGimg{
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;

}


/*Popular_equipment_categories  */
.MainPage_categories_slide_wrapper{
    margin-bottom: 5%;
}
.MainPage_categories_flex{
    display: flex;
    justify-content: space-around;
    gap: 5px;
}
.MainPage_equipment_element{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3% 3% 0;
    background-color: rgba(247, 248, 251, 1);
    border-radius: 30px;
    flex: 30%;
    cursor: pointer;

}
.MainPage_equipment_text{
    width: 60%;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}
.MainPage_equipment_text img{
    padding: 0 2% ;
}
.MainPage_equipment_img{
    width: 100%;
}
#MainPage_hidden_block{
    display: none;
}
#MainPage_hidden_block p{
    color: rgba(157, 161, 173, 1);
 
}
/* Blue_block */
.MainPage_Blue_block{
    margin:  5% 0;
    border-radius: 30px;
    width: 100%;
   padding: 5%;
   display: flex;
   /* flex-direction: column;
   align-items: center;
   justify-content: center; */
   justify-content: space-between;
   position: relative;
   background: url('../../img/Slice\ 1bcgBlue.svg') 0 50%/100% auto no-repeat;
}
.MainPage_Blue_block p{
    color: rgba(255, 255, 255, 1);
    font-size: 30px;
    flex: 50%;
}
.MainPage_Blue_block img{
    position: absolute;
    z-index: -10;
    width: 100%;
}
.MainPage_button_wrap{
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Blue_block_button{
    /* height: 90px;
    width: 280px; */
    border: none;
    background-color: rgba(255, 255, 255, 1);
    padding: 5% 10%;
    border-radius: 90px;
   display: flex;
   align-items: center ;
   justify-content: space-between;
   color:rgba(20, 25, 43, 1);
   border: 1px solid  rgba(255, 255, 255, 1);
   font-size: 1.2em;
   font-weight: 600;
}
.Blue_block_button:hover{
    background-color:#2B59FA;
    color:#fff;
    border-color: #2B59FA;
    cursor: pointer;
    transition: 0.3s;

}

@media (max-width:1920px) {
    .MainPage_main_section h1 {
        width: 600px;
        margin-bottom: 20px;
    }

    #MainPage_HeadImg {
        right: 10%;
    }
}
@media (max-width:1440px) {
    /* .MainPage_main_section_text{
        padding-top: 2%;
    } */

    .MainPage_main_section h1{
        width: 50%;
        margin-bottom: 20px;
    }
    #MainPage_HeadImg {
        right: 2%;
    }
}
@media (max-width:1024px) {

    .MainPage_main_section h1{
        width: 280px;
    }
    .MainPage_buttons_flex{
      flex-direction: column;
      gap: 0;
      margin-bottom: 10px;
    }
    .MainPage_title_button{
        margin-bottom: 5px;
    }
    .MainPage_item_name h3{
        width: 100%;
    padding-left: 10px;
    }
    .MainPage_flex_number{
        width: 60px;
        height: 60px;
    }
    .MainPage_Blue_block p{
        font-size: 20px;
    }
}

@media (max-width:768px) {
    .MainPage_flex_item__title{
        padding: 15px 5px;
        font-size: 1.2em;
    }
    .MainPage_flex_number{
        width: 45px;
        height: 45px;
    }
  
}

@media (max-width:725px) {
    .MainPage_title_h2,
    .MainPage_chaos_title{
        padding: 5% 0; 
        text-align: left;
        width: 100%;
      
    }
    .MainPage_flex_items{
        flex-wrap: wrap;
    }
    .MainPage_flex_item{
        width: 45%;
    }

    #MainPage_HeadImg{
        display: none;
    }
    #MainPage_HeadImg2{
        display: flex;
        width: 100%;
    }
    .MainPage_flex_item__title{
        padding: 10px 10px;
    }
    .MainPage_grid_wrapper1{
        grid-template-columns: 1fr ;
        margin-bottom: 10px;
        gap: 10px;
    }
    .MainPage_grid_wrapper2{
        grid-template-columns: 1fr ;
        gap: 10px;
    }
    .MainPage_flex_box{
        flex-direction: column;
        margin-bottom: 20px;
    }
    .MainPage_flex_elem{
        width: 94%;
        margin-left: 6%;
        border-radius: 20px;
        padding: 7% 7% 7% 12%;
        overflow: visible;
    }
    .MainPage_flex_number{
        top: 15%;
        left: -6%;
    }
    .MainPage_support_block{
        flex-direction: column;
    }
    .MainPage_phone1 {
       display: none;
    }
    .MainPage_phone2{
     display: block;
    }
    #MainPage_hidden_block{
        display: flex;
        gap: 10px;
        padding: 0 0 5% ;
    }
   
    .MainPage_categories_slide_wrapper{
        width: 100%;
        height: 350px;
        overflow: hidden;
        position: relative;
        overflow-x: scroll;
        transition: 2s;
    }
    .MainPage_categories_flex{
        position: absolute;
        width: 900px;
    }
    .MainPage_equipment_element{
        padding: 3% 3% 0;
    }
    .MainPage_Blue_block{
        flex-direction: column;
        text-align: center;
        padding: 5% 14%;
    }
    .MainPage_Blue_block p{
        padding-bottom: 4%;
    }
}


@media (max-width:490px) {
    .MainPage_flex_item {
        width: 100%;
    }
}