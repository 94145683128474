.equipment_element_inner{
    flex: 33.33%;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    border-radius: 30px;
    background-color: rgba(247, 248, 251, 1);
}
.equipment_element_inner:hover{
    box-shadow:rgb(170, 175, 189) 8px 5px 20px 0px;
    transition: 0.5s;
    transform: scale(1.01)
}
.hardware_elem{
    height: 100%;
}
.hardware_categories_flex{
  padding-top: 40px;
}
.Hardware_button{
    border: none;
    background-color: rgba(43, 89, 250, 1);
    padding: 15px 25px ;
    border-radius: 90px;
     display: flex;
   align-items: center ;
   justify-content: center;
   color:white;
   font-size: 1em;
   border: 1px solid  rgba(43, 89, 250, 1);
   margin: -20px auto 0;

 
}
.Hardware_button:hover{
    background-color: #fff;
    color:rgba(43, 89, 250, 1);
    border: 1px solid rgba(43, 89, 250, 1);
    transition: 0.3s;
    cursor: pointer;
    
}
.Hardware_button:active{
    background-color: rgba(223, 227, 240, 1);
    color:rgba(43, 89, 250, 1);
    border: 1px solid rgba(223, 227, 240, 1);
    transition: 0.3s;
    
}

@media (max-width:725px) {
    .Hardware_button{
        position: absolute;
        top: 60px;
        right: 15px;
    }
    .equipment_text{
        font-size: 1em;
    }
}
@media (max-width:375px) {
    .Hardware_button{
        right: 5px;
        padding: 15px 12px ;
        font-size: 0.7em;
    }
}
@media (max-width:320px) {
    .Hardware_button{
        right: 15px;
        padding: 7px 10px ; 
    
    }
   
}